import React, { useState } from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'

function createMenuHierarchy(menuData, menuName) {
  let tree = [],
    mappedArr = {},
    arrElem,
    mappedElem

  // First map the nodes of the array to an object -> create a hash table.
  for (let i = 0, len = menuData.length; i < len; i++) {
    arrElem = menuData[i].node
    if (arrElem.menu_name === menuName && arrElem.enabled === true) {
      mappedArr[arrElem.drupal_id] = arrElem
      if (arrElem.drupal_parent_menu_item != null) {
        let stripped_drupal_id = arrElem.drupal_parent_menu_item.replace('menu_link_content:', '')
        mappedArr[arrElem.drupal_id].drupal_parent_menu_item = stripped_drupal_id
      }
      mappedArr[arrElem.drupal_id]['children'] = []
    }
  }

  for (let id in mappedArr) {
    if (mappedArr.hasOwnProperty(id)) {
      mappedElem = mappedArr[id]
      // If the element is not at the root level, add it to its parent array of children.
      if (mappedElem.drupal_parent_menu_item) {
        mappedArr[mappedElem.drupal_parent_menu_item]['children'].push(mappedElem)
      }
      // If the element is at the root level, add it to first level elements array.
      else {
        tree.push(mappedElem)
      }
    }
  }
  return tree
}

const Menu = ({ menuName }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMenuLinkContentFoundationMenu(
            sort: { order: ASC, fields: weight }
            filter: { menu_name: { eq: "foundation-menu" } }
          ) {
            edges {
              node {
                enabled
                title
                expanded
                external
                link {
                  uri
                  url
                }
                drupal_parent_menu_item
                bundle {
                  drupal_internal__target_id
                }
                drupal_id
                menu_name
              }
            }
          }
        }
      `}
      render={(data) => {
        if (data.allMenuLinkContentFoundationMenu.edges.length < 1) {
          return null
        }

        let menuArray = createMenuHierarchy(data.allMenuLinkContentFoundationMenu.edges, menuName)
        const [state, setState] = useState({
          visible: false,
        })

        const toggleSubmenu = () => {
          setState({
            visible: !state.visible,
          })
        }

        return (
          <li className={'nav-item ' + menuName}>
            <a className="nav-link btn btn-light main-menu-toggle" href="#" onClick={toggleSubmenu}>
              <span className="navbar-toggler-icon"></span>
              Menu
            </a>
            <div className={`main-menu-content ${state.visible ? 'd-block' : 'd-none'}`}>
              <Submenu menuArray={menuArray} />
            </div>
          </li>
        )
      }}
    />
  )
}

const MenuLink = ({ link }) => {
  // Early return if no submenu items.
  if (link.children.length === 0) {
    return (
      <a className="menu-link-item" href={link.link.url} key={link.drupal_id}>
        {link.title}
      </a>
    )
  }

  const [state, setState] = useState({
    visible: false,
  })

  const toggleSubmenu = () => {
    setState({
      visible: !state.visible,
    })
  }

  function getLink(link) {
    if (!link.external && link.link.url) {
      return (
        <Link className="menu-link-item" to={link.link.url}>
          {link.title}
        </Link>
      )
    } else if (!link.external && link.link.uri.includes('internal:')) {
      return (
        <Link className="menu-link-item" to={link.link.uri.replace('internal:', '')}>
          {link.title}
        </Link>
      )
    } else {
      return (
        <a href={link.link.url} className="menu-link-item external">
          {link.title}
        </a>
      )
    }
  }

  function getSubmenuIcon() {
    if (state.visible) {
      return (
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          className="bi bi-caret-up-fill"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
        </svg>
      )
    }

    return (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        className="bi bi-caret-down-fill"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
      </svg>
    )
  }

  return (
    <div className="has-submenu" key={link.drupal_id}>
      <div className="menu-link-item has-submenu d-flex justify-content-between">
        {getLink(link)}
        {/*<span onClick={toggleSubmenu}>{getSubmenuIcon()}</span>*/}
      </div>
      {/*This line uses state.*/}
      {/*<div className={`submenu ${state.visible ? 'd-block' : 'd-none'}`}>*/}
      <div className={`submenu ${link.expanded ? 'd-block' : 'd-none'}`}>
        <Submenu menuArray={link.children} />
      </div>
    </div>
  )
}

const Submenu = ({ menuArray }) => {
  let menu = []
  for (let item in menuArray) {
    menu.push(<MenuLink link={menuArray[item]} key={menuArray[item].drupal_id} />)
  }

  return menu
}

Menu.propTypes = {
  menuName: PropTypes.string,
}

Menu.defaultProps = {
  menuName: `main`,
}

export default Menu
