import React from 'react'
import { Link } from 'gatsby'
import { siteMetadata } from '../../../gatsby-config'

import IspeIcon from './../../../assets/logo-icon.svg'
import './navi.scss'
import Menu from 'components/menu/menu'

class Navi extends React.Component {
  render() {
    const { location } = this.props
    return (
      <nav className="navbar navbar-light navbar-expand-lg main-menu">
        <div className="container">
          <div className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link btn home" to="/">
                <IspeIcon className="d-inline-block m-auto align-top" alt="ispe-logo" />
                <span className="d-none d-md-inline-block ml-2">{siteMetadata.title}</span>
              </Link>
            </li>

            <li className={location.pathname === '/' ? 'nav-item active' : 'nav-item'}>
              <Link to="/donate" className="nav-link btn donate">
                Donate
              </Link>
            </li>

            <Menu menuName="foundation-menu" />
          </div>
        </div>
      </nav>
    )
  }
}

export default Navi
