require('dotenv').config({
  path: `.env.${process.env.NODE_ENV}`,
})

module.exports = {
  siteMetadata: {
    title: 'ISPE Foundation',
    description: 'ISPE Foundation',
    siteUrl: process.env.SITE_URL || 'https://ispefoundation.org',
    drupalUrl: process.env.DRUPAL_URL || 'https://ispe.org',
    author: 'Unleashed Technologies',
    twitter: 'ispeorg',
  },
  pathPrefix: '/',
  plugins: [
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/assets/`,
        name: 'images',
      },
    },
    {
      resolve: `gatsby-source-drupal`,
      options: {
        baseUrl: process.env.DRUPAL_URL,
        apiBase: `jsonapi`,
        disallowedLinkTypes: [
          `self`,
          `describedby`,
          `contact_message--feedback`,
          `contact_message--personal`,
          `file--file`,
          `media--image`,
          `node--magazine_article`,
          `node--news_item`,
          `node--page`,
          `node--video`,
          `webform--webform`,
        ],
        filters: {
          'paragraph--foundation_carousel_slide': 'include=field_slide_image',
          // 'paragraph--featured_content_item': 'include=field_slide_image', @Todo: Add image featured image field.
          'paragraph--featured_content_item':
            'include=field_node.field_teaser_image,field_node.field_page_banner_image',
          'paragraph--icon-collection-item': 'include=field_image',
          'paragraph--webform_embed': 'include=field_webform',
          'node--foundation_page': 'include=field_banner.field_slide_image',
          'media--svg': 'include=field_media_svg',
          // 'media--image': 'include=field_media_image',
        },
      },
    },
    {
      resolve: 'gatsby-plugin-react-svg',
      options: {
        rule: {
          include: /assets/,
        },
      },
    },
    // {
    //   resolve: `gatsby-plugin-manifest`,
    //   options: {
    //     name: 'ISPE Foundation',
    //     short_name: 'ISPE Foundation',
    //     description: 'International Society for Pharmaceutical Engineering Foundation',
    //     homepage_url: 'https://ispefoundation.org',
    //     start_url: '/',
    //     background_color: '#ffffff',
    //     theme_color: '#2199e8',
    //     display: 'standalone',
    //     icons: [
    //       {
    //         src: '/img/android-chrome-192x192.png',
    //         sizes: '192x192',
    //         type: 'image/png',
    //       },
    //       {
    //         src: '/img/android-chrome-512x512.png',
    //         sizes: '512x512',
    //         type: 'image/png',
    //       },
    //     ],
    //   },
    // },
    {
      resolve: 'gatsby-plugin-google-tagmanager',
      options: {
        id: process.env.GTM_ID,
      },
    },
    {
      resolve: 'gatsby-plugin-sharp',
      options: {
        defaultQuality: 75,
      },
    },
    'gatsby-plugin-catch-links',
    'gatsby-plugin-offline',
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-sass',
    'gatsby-plugin-sitemap',
    'gatsby-plugin-twitter',
    'gatsby-transformer-sharp',
  ],
}
