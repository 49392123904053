import React from 'react'
import Slider from 'react-slick'
import { graphql, StaticQuery } from 'gatsby'
import get from 'lodash/get'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './footer.scss'

export default function Footer() {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              drupalUrl
            }
          }
          globalConfig: configPagesGlobalConfigurations {
            field_donors
          }
        }
      `}
      render={(data) => <FooterTemplate data={data} />}
    />
  )
}

const FooterTemplate = ({ data }) => {
  const drupalUrl = get(data, 'site.siteMetadata.drupalUrl', 'https://ispe.org')
  let donors = get(data, 'globalConfig.field_donors')

  const settings = {
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToScroll: 1,
    variableWidth: true,
  }

  return (
    <footer className="footer">
      {donors && (
        <div className="donors-container py-3">
          <div className="text-center container-fluid donors-content">
            <div className="d-flex flex-column flex-md-row">
              <div className="flex-grow-1 text-nowrap mr-3 mb-2 mb-md-0">
                <strong className="text-primary">Thank You to Our Donors:</strong>
              </div>
              <div className="w-100 h-100 position-relative">
                <div className="slider-container">
                  <Slider className="" {...settings}>
                    {donors.map((donor) => (
                      <div className="donor px-3 px-md-5" key={donor}>
                        {donor}
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="container main-content text-light text-center text-md-left pt-2 pb-4">
        <hr className="border-light" />
        <div className="row">
          <div className="col-12 col-md-9">
            <ul>
              <li>
                {/* @Todo: Get these urls dynamically from the footer menu. */}
                <a href={drupalUrl + '/about/contact-us'}>Contact Us</a>
              </li>
              <li>
                <a href={drupalUrl + '/about/frequently-asked-questions'}>Terms of Use</a>
              </li>
              <li>
                <a href={drupalUrl + '/terms-use'}>Privacy Policy</a>
              </li>
            </ul>
            <p>Copyright © 2021 ISPE | International Society for Pharmaceutical Engineering </p>
          </div>

          <div className="col-12 col-md-3">
            <ul className="text-md-right icons no-text">
              <li>
                <a href="https://www.facebook.com/ISPEorg/" target="_blank">
                  <span className="icon icon-facebook" aria-hidden="true"></span>
                  <span className="icon_text">Facebook</span>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/214189" target="_blank">
                  <span className="icon icon-linkedin" aria-hidden="true"></span>
                  <span className="icon_text">Linkedin</span>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/ISPEorg" target="_blank">
                  <span className="icon icon-twitter" aria-hidden="true"></span>
                  <span className="icon_text">Twitter</span>
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UCPjbzpfUTqzC5ZN-UVJncgg" target="_blank">
                  <span className="icon icon-youtube" aria-hidden="true"></span>
                  <span className="icon_text">Youtube</span>
                </a>
              </li>
              <li>
                <a href="mailto:ask@ispe.org" target="_blank">
                  <span className="icon icon-mail2" aria-hidden="true"></span>
                  <span className="icon_text">Mail2</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}
